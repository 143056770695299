<template>
    <div style="text-align:center;">
        <div class="h-handle-bg">
            <div class="h-handle-button">
				<p class="h-return">
					<el-button type="text" size="small" @click="back()">返回</el-button>
				</p>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="save"
                        v-right-code="'Stocklotmanage:Save'">&nbsp;保存&nbsp;</el-button>
                </div>
            </div>
        </div>
        <br /><br />
        <el-row style="text-align:center;font-size: 20px;">
            <el-col :span="2">
                <span>批属性</span>
            </el-col>
            <el-col :span="6">
                <span>自定义名称</span>
            </el-col>
            <el-col :span="4">
                <span>属性格式</span>
            </el-col>
            <el-col :span="6">
                <span>属性选项</span>
            </el-col>
            <el-col :span="2">
                <span>是否可见</span>
            </el-col>
			<el-col :span="2">
			    <span>是否库存验证</span>
			</el-col>
            <el-col :span="2">
                <span>是否必填</span>
            </el-col>
        </el-row>
        <br />
        <br />
        <el-row v-for="(col,index)  in dataSource.tableData" :key="index" style="margin-bottom: 15px;">
            <el-col :span="2">
                <span>{{col.DefaultLotName}}：</span>
            </el-col>
            <el-col :span="6">
                <el-input size="small" :disabled="col.Sort==0" v-model="col.LotName" maxlength="50" placeholder="自定义名称">
                </el-input>
            </el-col>
            <el-col :span="4">
                <el-select size="small" :disabled="col.Sort==0" v-model="col.LotType" @change="lotTypeChange(col)" placeholder="属性格式">
                    <el-option v-for="item in lottypes" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="6">
                <el-input size="small" :disabled="col.Sort==0" v-if="col.LotType==2" v-model="col.LotValue" type="number"
                    oninput="this.value=this.value.replace(/[^0-9]+/,'');" placeholder="属性选项-数字（整数）">
                </el-input>
                <el-input size="small" :disabled="col.Sort==0" v-else-if="col.LotType==3" v-model="col.LotValue" type="number"
                    oninput="this.value=this.value.replace(/[^0-9.]+/,'');" placeholder="属性选项-数字（小数）">
                </el-input>
                <el-date-picker :disabled="col.Sort==0" v-else-if="col.LotType==4" v-model="col.LotValue" type="date" value-format="yyyy-MM-dd"
                    placeholder="属性选项-日期">
                </el-date-picker>
                <el-select :disabled="col.Sort==0" v-else-if="col.LotType==5" v-model="col.SelectList" style="width: 100%;" multiple filterable
                    allow-create default-first-option placeholder="属性选项-下拉选择">
                    <el-option v-for="item in col.SelectList" :key="item" :label="item" :value="item">
                    </el-option>
                </el-select>
                <el-input size="small" :disabled="col.Sort==0" v-else v-model="col.LotValue" placeholder="属性选项">
                </el-input>
            </el-col>
            <el-col :span="2">
                <el-checkbox :disabled="col.Sort==0" v-model="col.IsDisplay"></el-checkbox>
            </el-col>
			<el-col :span="2">
			    <el-checkbox v-model="col.IsStockVerify"></el-checkbox>
			</el-col>
            <el-col :span="2">
                <el-checkbox v-model="col.IsRequired"></el-checkbox>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    export default {
        mounted() {
          /*  this.Utils.lazy(() => {
            });
            this.initialize(); */
        },
        data() {
            return {
                lottypes: [
                    { label: '文本', value: 1 },
                    { label: '数字（整数）', value: 2 },
                    { label: '数字（小数）', value: 3 },
                    { label: '日期', value: 4 },
                    { label: '下拉选择', value: 5 },
                ],
                otherRuleValidate: {
                },
            }
        },
        props: {
            option: {},
			dataSource:{
				tableData:[]
			}
        },
        methods: {
          /*  initialize() {
                this.$ajax.send("omsapi/stockLotManage/findList", "post", {}, (data) => {
                    this.tableData = data.Result;
                });
            }, */
			back() {
				this.$parent.checkSaveStatus();
			},
            lotTypeChange(row) {
                this.dataSource.tableData.forEach(lot => {
                    if (lot.Sort == row.Sort) {
                        lot.LotValue = null;
                    }
                });
            },
            save() {
               this.dataSource.tableData.forEach(lot => {
                    if (lot.LotType == 5 && lot.SelectList && lot.SelectList.length > 0) {
                        lot.LotValue = lot.SelectList.join(',');
                    }
                });
                this.$ajax.send("omsapi/stockLotManage/save", "post", this.dataSource.tableData, (data) => {
                    this.Utils.messageBox("保存成功.", "success");
					 this.$parent.syncDataSource();
                });
            }
        }
    }
</script>
<style scoped>
    .from-item-left {
        margin-left: 50px;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
</style>